import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import styled from "styled-components";
import firebase from "firebase/compat/app";

import { withFirebaseAuth } from "js/react/views/Auth/FirebaseAuthContext";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import { RemoveSplashScreen } from "js/core/SplashScreen";
import { serverContext } from "js/config";

const MANDATORY_QUERY_PARAMS = ["scope", "access_type", "response_type", "state", "redirect_uri", "client_id"];

const ErrorContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #EEEEEF;
    color: #666666;
`;

function AuthHandler({ auth }: { auth: firebase.auth.Auth }) {
    useEffect(() => {
        (async () => {
            const isNewUser = new URLSearchParams(window.location.search).get("signup") === "true";
            const idToken = await auth.currentUser.getIdToken();
            const cookieAttributes: Cookies.CookieAttributes = { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) };
            Cookies.set("is_new_user", isNewUser.toString(), cookieAttributes);
            Cookies.set("id_token", idToken, cookieAttributes);
            // The server will check the cookie, get the token and continue the oauth2 flow
            window.location.reload();
        })();
    }, []);

    return (<FetchingClickShield visible backgroundColor={"transparent"} />);
}

const Oauth2Auth = withFirebaseAuth(function Oauth2Auth({ auth }) {
    const [isReadyForAuth, setIsReadyForAuth] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const missingParams = MANDATORY_QUERY_PARAMS.filter(param => !urlParams.has(param));
            if (missingParams.length > 0) {
                setErrorMessage(`Missing mandatory parameters: ${missingParams.join(", ")}`);
                RemoveSplashScreen();
                return;
            }

            if (urlParams.get("prompt") === "login" && auth.currentUser) {
                await auth.signOut();
                urlParams.delete("prompt");
                // @ts-ignore
                window.location = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
                return;
            }

            setIsReadyForAuth(true);
        })();
    }, []);

    if (errorMessage) {
        return (<ErrorContainer><div>ERROR</div><div>{errorMessage}</div></ErrorContainer>);
    }

    if (!isReadyForAuth) {
        return null;
    }

    return (
        <Authenticate
            defaultPage="createAccount"
            reason="provisioning"
            provisioningSourceId={serverContext.provisioningSourceId}
            provisioningSourceName={serverContext.provisioningSourceName}
        >
            <AuthHandler auth={auth} />
        </Authenticate>
    );
});

export default Oauth2Auth;
